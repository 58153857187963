.messendgerInput::placeholder {
    font-family: IBMPlexSans, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 2.24rem */
}


.messendgerInput {
    font-family: IBMPlexSans, sans-serif;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 2.24rem */
}