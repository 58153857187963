.cohort_module_cards_wrapper {
    min-width: 32.5%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 20px;
    overflow-y: scroll;
    padding-bottom: 18px;
    .warpper {
     overflow-x: clip;
    .glare_div {
        height: 100%;
        max-width: auto;
        img {
            height: 100% !important;
        }
        animation: 1500ms carousell linear infinite;
        transform-style: preserve-3d;

        @keyframes carousell {
            0% {
                transform: translateX(-30%);
            }

            100% {
                transform: translateX(100%);
            }
        }
    }
    .chort_module_card {
        padding: 28px 24px;
        gap: 8px;
        border-radius: 12px;
        border: 1px solid var(--GS-learn-Light-mode-Grey-3, rgba(3, 10, 33, 0.20));
        // background: var(--GS-learn-Surface, #FFF);
        cursor: pointer;
        min-width: 100%;
        max-width: 432px;
    }
    .active {
        background: #F7F7F8;
        border-color: black;
    }
    .chort_module_card:hover {
        background: #F7F7F8;
    }
}
}

@media screen and (max-width: 800px) {
    .cohort_module_cards_wrapper {
        width: 100%;
        padding: 0px 24px;
        overflow-y: scroll;
        padding-bottom: 250px;
    }
}