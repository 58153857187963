.messenger {
    opacity: 0;
    transform: translateY(20px);
    /* Initial translate position */
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.show {
    opacity: 1;
    transform: translateY(0);
}

.hide {
    opacity: 0;
    transform: translateY(20px);
}

.messenger_bg {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.showbg {
    opacity: 0.5;

}

.hidebg {
    opacity: 0;
}