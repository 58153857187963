@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .scroll-smooth-imp {
      scroll-behavior: smooth !important;
    }

    .no-highlight {
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .fade97 {
      background-image: linear-gradient(
        to right,
        #030a21 0%,
        #ffffff00 3%,
        #ffffff00 97%,
        #030a21 100%
      );
    }

    /* To show parent above child */

    /* Add to parent */
    .preserve3d {
      transform-style: preserve-3d;
    }

    /* Add to child */
    .translateZ10 {
      transform: translateZ(-10px);
    }

    .mentor-image-clip {
      width: 22.6rem;
      height: 28rem;
      -webkit-clip-path: url(#my-clip-path);
      clip-path: url(#my-clip-path);

      /* ! Can use the below approach also  */
      /* clip-path: polygon(0.0442000% 53.3810000%, 0.4420000% 53.3810000%, 0.8020000% 41.0430000%, 1.9460000% 30.7160000%, 3.9520000% 22.2320000%, 6.8960000% 15.4220000%, 10.8560000% 10.1200000%, 15.9080000% 6.1580000%, 22.1300000% 3.3680000%, 29.5990000% 1.5830000%, 38.3910000% 0.6350000%, 48.5840000% 0.3560000%, 48.5840000% 0.3560000%, 58.9040000% 0.5640000%, 68.0320000% 1.3220000%, 75.9840000% 2.8330000%, 82.7760000% 5.2980000%, 88.4220000% 8.9180000%, 92.9380000% 13.8970000%, 96.3390000% 20.4340000%, 98.6390000% 28.7340000%, 99.8540000% 38.9960000%, 100.0000000% 51.4230000%, 100.0000000% 51.4230000%, 99.1380000% 64.1550000%, 97.3410000% 74.4460000%, 94.6050000% 82.5540000%, 90.9220000% 88.7390000%, 86.2850000% 93.2610000%, 80.6900000% 96.3790000%, 74.1280000% 98.3520000%, 66.5940000% 99.4410000%, 58.0820000% 99.9030000%, 48.5840000% 100.0000000%, 48.5840000% 100.0000000%, 40.0940000% 99.9010000%, 32.2920000% 99.4390000%, 25.2260000% 98.3710000%, 18.9460000% 96.4530000%, 13.4980000% 93.4390000%, 8.9310000% 89.0850000%, 5.2940000% 83.1460000%, 2.6340000% 75.3790000%, 1.0010000% 65.5390000%, 0.4420000% 53.3810000%);
      overflow: hidden; */
    }
  }
}

:root {
  /* font-size set to 62.5% for easy px to rem conversion */

  font-size: 62.5%;
  /* 10px default rem */

  /* Typography family */
  --PLEX_SANS: IBMPlexSans, sans-serif;
  --PLEX_SANS_CONDENSED: IBMPlexSansCondensed, sans-serif;

  /* Typography Weights */
  --REGULAR: 400;
  --MEDIUM: 500;
  --SEMI_BOLD: 600;

  /* Typography Font sizes */
  /* Leaving px values for reference */
  /* --F12: 12px;
  --F14: 14px;
  --F16: 16px;
  --F20: 20px;
  --F24: 24px;
  --F32: 32px; */

  --F12: 12px;
  --F14: 14px;
  --F16: 16px;
  --F18: 18px;
  --F20: 20px;
  --F22: 22px;
  --F24: 24px;
  --F26: 26px;
  --F28: 28px;
  --F30: 30px;
  --F32: 32px;

  /* Typography Line Heights */
  /* Leaving px values for reference */
  /* --L0: 1;
  --L1: 44.8px;
  --L2: 33.6px;
  --L3: 32px;
  --L4: 28.8px;
  --L5: 25.6px;
  --L6: 24px;
  --L7: 22.4px;
  --L8: 20px;
  --L9: 19.2px;
  --L10: 16.8px;
  --L11: 16px;
  --L12: 14.4px;
  --L13: 12px; */

  /* --L0: 1;
  --L1: 4.48rem;
  --L2: 3.36rem;
  --L3: 3.2rem;
  --L4: 2.88rem;
  --L5: 2.56rem;
  --L6: 2.4rem;
  --L7: 2.24rem;
  --L8: 2rem;
  --L9: 1.92rem; */
  --L10: 100%;
  --L11: 110%;
  --L12: 120%;
  --L13: 130%;
  --L14: 140%;
  --L15: 150%;
  --L16: 160%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: Arial, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
  font-family: var(--PLEX_SANS);

  /* Disabling blue highlight when tapping or pressing all elemtents */
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.flexCenter {
  @apply flex justify-center items-center;
}

.flexBetween {
  @apply flex justify-between items-center;
}

.flexStart {
  @apply flex items-center justify-start;
}

.paddings {
  @apply lg:px-20 py-6 px-5;
}

.truncateLine {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.swiper-pagination-bullet {
  background-color: grey !important;
  width: 13px !important;
  height: 13px !important;
}
.swiper-pagination-bullet-active {
  background-color: #030a21 !important;
  width: 25px !important;
  height: 13px !important;
  border-radius: 50px !important;
}

.truncateLine1 {
  -webkit-line-clamp: 1;
}

.truncateLine2 {
  -webkit-line-clamp: 2;
}

.truncateLine4 {
  -webkit-line-clamp: 4;
}

.hideScrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hideScrollbar::-webkit-scrollbar {
  display: none;
}

/* Navbar */
.navbar {
  @apply py-4 px-8 bg-gslearnSurface border-b border-gslearnlightmodeGrey3 gap-4;
}

.footer {
  @apply py-4 px-8 bg-gslearnSurface border-t border-gslearnlightmodeGrey3;
}

@layer base {
  /* PlexSans styling class */
  .Desktop_Tag_Card {
    font-family: var(--PLEX_SANS_CONDENSED);
    font-weight: var(--MEDIUM);
    font-size: var(--F12);
    line-height: var(--L10);
    text-transform: uppercase;
    letter-spacing: 6%;
  }

  .Heading_1_S_20_160 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--SEMI_BOLD);
    font-size: var(--F20);
    line-height: var(--L16);
  }

  .Heading_1_S_20_120 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--SEMI_BOLD);
    font-size: var(--F20);
    line-height: var(--L12);
  }

  .Heading_1_M_20_140 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--MEDIUM);
    font-size: var(--F20);
    line-height: var(--L14);
  }

  .Heading_1_M_20_160 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--MEDIUM);
    font-size: var(--F20);
    line-height: var(--L16);
  }

  .Heading_1_M_20_120 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--MEDIUM);
    font-size: var(--F20);
    line-height: var(--L12);
  }

  .Body_1_M_16_160 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--MEDIUM);
    font-size: var(--F16);
    line-height: var(--L16);
  }

  .Body_1_M_16_120 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--MEDIUM);
    font-size: var(--F16);
    line-height: var(--L12);
  }

  .Body_1_M_16_140 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--MEDIUM);
    font-size: var(--F16);
    line-height: var(--L14);
  }

  .Body_1_M_20_120 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--MEDIUM);
    font-size: var(--F20);
    line-height: var(--L12);
  }

  .Body_1_M_16 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--MEDIUM);
    font-size: var(--F16);
  }

  .Body_1_S_14_120 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--SEMI_BOLD);
    font-size: var(--F14);
    line-height: var(--L12);
  }

  .Body_1_R_16_160 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--REGULAR);
    font-size: var(--F16);
    line-height: var(--L16);
  }

  .Body_1_R_16_120 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--REGULAR);
    font-size: var(--F16);
    line-height: var(--L12);
  }

  .Body_2_M_14_120 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--MEDIUM);
    font-size: var(--F14);
    line-height: var(--L12);
  }

  .Body_2_R_14_160 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--REGULAR);
    font-size: var(--F14);
    line-height: var(--L16);
  }

  .Body_2_R_14_120 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--REGULAR);
    font-size: var(--F14);
    line-height: var(--L12);
  }

  .Body_2_R_14_130 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--REGULAR);
    font-size: var(--F14);
    line-height: var(--L13);
  }

  .Body_2_R_14_140 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--REGULAR);
    font-size: var(--F14);
    line-height: var(--L14);
  }

  .Body_2_R_14_0 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--REGULAR);
    font-size: var(--F14);
    line-height: var(--L0);
  }

  .Body_1_Semibold_16_120 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--SEMI_BOLD);
    font-size: var(--F16);
    line-height: var(--L12);
  }

  .Caption_R_12_120 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--REGULAR);
    font-size: var(--F12);
    line-height: var(--L12);
  }

  .Caption_R_12_120_10 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--REGULAR);
    font-size: var(--F12);
    line-height: var(--L12);
    letter-spacing: 10%;
  }

  .Custom_Caption_R_14_120 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--REGULAR);
    font-size: var(--F14);
    line-height: var(--L12);
  }

  .UI_Button_Medium_14_120 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--MEDIUM);
    font-size: var(--F14);
    line-height: 120%;
  }

  .UI_Button_Medium_16_120 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--MEDIUM);
    font-size: var(--F16);
    line-height: var(--L12);
  }

  /* PlexSansCondensed styling*/
  .C_Heading_1_M_32_140 {
    font-family: var(--PLEX_SANS_CONDENSED);
    font-weight: var(--MEDIUM);
    font-size: var(--F32);
    line-height: var(--L14);
  }

  .C_Heading_2_M_24_120 {
    font-family: var(--PLEX_SANS_CONDENSED);
    font-weight: var(--MEDIUM);
    font-size: var(--F24);
    line-height: var(--L12);
  }

  .C_Heading_1_M_24_140 {
    font-family: var(--PLEX_SANS_CONDENSED);
    font-weight: var(--MEDIUM);
    font-size: var(--F24);
    line-height: var(--L14);
  }

  .C_Heading_2_M_20_120 {
    font-family: var(--PLEX_SANS_CONDENSED);
    font-weight: var(--MEDIUM);
    font-size: var(--F20);
    line-height: var(--L12);
  }

  .C_Heading_3_R_20_100 {
    font-family: var(--PLEX_SANS_CONDENSED);
    font-weight: var(--REGULAR);
    font-size: var(--F20);
    line-height: var(--L100);
    letter-spacing: 10%;
  }

  .C_Heading_5_M_12_0 {
    font-family: var(--PLEX_SANS_CONDENSED);
    font-weight: var(--MEDIUM);
    font-size: var(--F12);
    line-height: var(--L0);
  }

  .C_Heading_5_R_12_100 {
    font-family: var(--PLEX_SANS_CONDENSED);
    font-weight: var(--REGULAR);
    font-size: var(--F12);
    line-height: var(--L10);
    letter-spacing: 10%;
  }

  .Heading_M_24_130 {
    font-family: var(--PLEX_SANS);
    font-weight: var(--MEDIUM);
    font-size: var(--F24);
    line-height: var(--L13);
  }

  /* new styles */

  .New_Display_1_M_70_130 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 70px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -1.5px;
  }

  .New_Display_2_M_40_130 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 40px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -1.55px;
  }

  .New_Display_3_M_36_130 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 36px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -1.55px;
  }

  .New_Display_4_M_34_120 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 34px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -1.55px;
  }

  .New_Display_5_M_32_120 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 32px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -1.55px;
  }

  .New_Heading_1_M_24_130 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: -0.5%;
  }

  .New_Heading_2_M_20_120 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 20px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0%;
  }

  .New_Body_1_M_16_130 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0%;
  }

  .New_Body_1_M_16_120 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0%;
  }

  .New_Body_1_R_16_160 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0%;
  }

  .New_Body_1_R_16_120 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0%;
  }

  .New_Body_2_M_14_140 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0%;
  }

  .New_Body_2_M_14_120 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 14px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0%;
  }

  .New_Body_2_R_14_160 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: 0%;
  }

  .New_Body_2_R_14_140 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0%;
  }

  .New_Body_2_R_14_135 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 14px;
    font-weight: 400;
    line-height: 135%;
    letter-spacing: 3.55px;
  }

  .New_Body_2_R_14_120 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0%;
  }

  .New_Body_3_R_12_120 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .New_Caption_R_12_140 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 12px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: 0%;
  }

  .New_Caption_R_12_120 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 4.2px;
  }

  .New_Display_M_28_135 {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 28px;
    font-weight: 500;
    line-height: 135%;
    letter-spacing: -1.55px;
  }

  .New_Body_1_M_16_120_ALLCAPS {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0%;
    text-transform: capitalize;
  }

  .New_Body_2_R_14_120_ALLCAPS {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0%;
    text-transform: capitalize;
  }

  .New_Caption_R_12_120_ALL_CAPS {
    font-family: var(--font-ibm-plex-sans);
    font-feature-settings: 'ss01' on, 'ss02' on;
    font-size: 12px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 4.2px;
    text-transform: capitalize;
  }

  /* wrapper classess */

  .wrapper_class {
    text-overflow: ellipsis;
    white-space: inherit;
    font-family: var(--PLEX_SANS);
    font-weight: var(--MEDIUM);
    font-size: var(--F20);
  }

  @media screen and (max-width: 768px) {
    /* only font size changes for these classes */
    .Heading_1_M_20_160,
    .Heading_1_M_20_120 {
      font-size: var(--F16);
    }

    .Body_1_M_16_120,
    .Body_1_R_16_160,
    .Body_1_R_16_120,
    .Body_1_M_16_160 {
      font-size: var(--F14);
    }

    .Body_2_R_14_160,
    .Body_2_R_14_120 {
      font-size: var(--F12);
    }

    .Body_2_R_14_0 {
      font-size: var(--F14);
      line-height: var(--L0);
    }

    .Caption_R_12_120_10 {
      letter-spacing: 6%;
    }

    .Custom_Caption_R_14_120 {
      font-size: var(--F14);
    }

    /* PlexSansCondensed styling*/
    .C_Heading_1_M_32_140,
    .C_Heading_2_M_24_120 {
      font-size: var(--F24);
    }

    .C_Heading_3_R_20_100 {
      font-size: var(--F16);
    }

    /* New styles */
  
    .New_Display_2_M_40_130,
    .New_Display_3_M_36_130 {
      font-size: 28px;
    }
  
    .New_Heading_1_M_24_130 {
      font-size: 20px;
      letter-spacing: 0%;
      line-height: 120%;
    }
  
    .New_Heading_2_M_20_120 {
      font-size: 16px;
    }
  
    .New_Body_1_M_16_120,
    .New_Body_1_R_16_160 {
      font-size: 14px;
    }
  
    .New_Body_2_M_14_120,
    .New_Body_2_R_14_140,
    .New_Body_3_R_12_120 {
      font-size: 12px;
    }
  
    .New_Body_2_R_14_120 {
      line-height: 160%;
    }
  
    .New_Caption_R_12_120 {
      font-size: 14px;
      letter-spacing: 0%;
    }
  
    .New_Body_1_R_16_120 {
      font-size: 14px;
      line-height: 160%;
    }
  }

  @font-face {
    font-family: 'IBMPlexSans';
    font-weight: 100;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans/IBMPlexSans-Thin.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSans';
    font-weight: 100;
    font-style: italic;
    src: url('/fonts/IBM_Plex_Sans/IBMPlexSans-ThinItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSans';
    font-weight: 200;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans/IBMPlexSans-ExtraLight.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSans';
    font-weight: 200;
    font-style: italic;
    src: url('/fonts/IBM_Plex_Sans/IBMPlexSans-ExtraLightItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSans';
    font-weight: 300;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans/IBMPlexSans-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSans';
    font-weight: 300;
    font-style: italic;
    src: url('/fonts/IBM_Plex_Sans/IBMPlexSans-LightItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSans';
    font-weight: 400;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans/IBMPlexSans-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSans';
    font-weight: 500;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans/IBMPlexSans-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSans';
    font-weight: 500;
    font-style: italic;
    src: url('/fonts/IBM_Plex_Sans/IBMPlexSans-MediumItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSans';
    font-weight: 600;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans/IBMPlexSans-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSans';
    font-weight: 600;
    font-style: italic;
    src: url('/fonts/IBM_Plex_Sans/IBMPlexSans-SemiBoldItalic.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSans';
    font-weight: 700;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans/IBMPlexSans-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSans';
    font-weight: 700;
    font-style: italic;
    src: url('/fonts/IBM_Plex_Sans/IBMPlexSans-BoldItalic.ttf') format('truetype');
  }

  /* IBM Plex Sans Condensed */

  @font-face {
    font-family: 'IBMPlexSansCondensed';
    font-weight: 100;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Thin.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSansCondensed';
    font-weight: 100;
    font-style: italic;
    src: url('/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-ThinItalic.ttf')
      format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSansCondensed';
    font-weight: 200;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-ExtraLight.ttf')
      format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSansCondensed';
    font-weight: 200;
    font-style: italic;
    src: url('/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-ExtraLightItalic.ttf')
      format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSansCondensed';
    font-weight: 300;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Light.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSansCondensed';
    font-weight: 300;
    font-style: italic;
    src: url('/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-LightItalic.ttf')
      format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSansCondensed';
    font-weight: 400;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Regular.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSansCondensed';
    font-weight: 500;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Medium.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSansCondensed';
    font-weight: 500;
    font-style: italic;
    src: url('/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-MediumItalic.ttf')
      format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSansCondensed';
    font-weight: 600;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-SemiBold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSansCondensed';
    font-weight: 600;
    font-style: italic;
    src: url('/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-SemiBoldItalic.ttf')
      format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSansCondensed';
    font-weight: 700;
    font-style: normal;
    src: url('/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'IBMPlexSansCondensed';
    font-weight: 700;
    font-style: italic;
    src: url('/fonts/IBM_Plex_Sans_Condensed/IBMPlexSansCondensed-BoldItalic.ttf')
      format('truetype');
  }
}

/* input[type='checkbox'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid black;
  color: #fff;
  border-radius: 3px;
  outline: none;
  position: relative;
  transition: background-color 0.3s;
} */

/* input[type='checkbox']:checked {
  background-color: black;

} */

input[type='checkbox']:checked::before {
  content: '';
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  width: 5px;
  height: 9px;
  border-radius: 0.1rem;
  border-color: white;
  border-width: 0 2px 2px 0;
  pointer-events: none;
}

/* .reactEasyCrop_Image{
  width: 25.5rem;
} */

input[type='range'] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  background: rgba(3, 10, 33, 0.1);
  border-radius: 8px;
  height: 0.6rem;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1.6rem;
  height: 1.6rem;
  background-color: #ffffff;
  border: 2px solid #030a21;
  border-radius: 50%;
  cursor: pointer;
}

/* Thumb: Firefox */
input[type='range']::-moz-range-thumb {
  width: 1.6rem;
  height: 1.6rem;
  background-color: #ffffff;
  border: 2px solid #030a21;
  border-radius: 50%;
}

.text_area__copy {
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* IE and Edge */
}

.text_area__copy {
  scrollbar-color: transparent transparent;
  /* Hide scrollbar in other browsers */
}

.text_area__copy::-webkit-scrollbar {
  display: none;
  /* Hide scrollbar in WebKit browsers (e.g., Chrome, Safari) */
}

/* styles.css */
/* Hide scrollbar in modern browsers */
/* Chrome, Safari, Edge, and newer versions of Firefox */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar in Internet Explorer and Edge Legacy */
body {
  -ms-overflow-style: none;
}

/* Hide scrollbar in Firefox (older versions) */
body {
  scrollbar-width: none;
}

/* Hide scrollbar in other browsers using the tailwind-scrollbar-hide utility */
body {
  /* Add the tailwind-scrollbar-hide utility class */
  @apply scrollbar-hide;
}

.height-custom {
  height: calc(100vh - 160px);
}

.tooltip::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%);
  border: 8px solid transparent;
  border-bottom-color: var(--tooltip-color);
}

@media (max-width: 768px) {
  .tooltip {
    display: none !important;
  }
.sentry-error-embed-wrapper {
  display: none !important;
}
}
